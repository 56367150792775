import {NAMES} from "./constants";

export const lossDataNewSet3 = {
    "Jan 01 2025": {
        [NAMES.TROOPS]: 790800,
        [NAMES.TANKS]: 9672,
        [NAMES.BMP_BTR]: 20043,
        [NAMES.ARTA]: 21532,
        [NAMES.PPO]: 1032,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 330,
        [NAMES.UAV]: 21131,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 32675
    },
    "Jan 02 2025": {
        [NAMES.TROOPS]: 792170,
        [NAMES.TANKS]: 9676,
        [NAMES.BMP_BTR]: 20056,
        [NAMES.ARTA]: 21552,
        [NAMES.PPO]: 1032,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 330,
        [NAMES.UAV]: 21200,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 32729
    },
    "Jan 03 2025": {
        [NAMES.TROOPS]: 793250,
        [NAMES.TANKS]: 9677,
        [NAMES.BMP_BTR]: 20070,
        [NAMES.ARTA]: 21555,
        [NAMES.PPO]: 1032,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 330,
        [NAMES.UAV]: 21249,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 32770
    },
    "Jan 04 2025": {
        [NAMES.TROOPS]: 794760,
        [NAMES.TANKS]: 9679,
        [NAMES.BMP_BTR]: 20093,
        [NAMES.ARTA]: 21578,
        [NAMES.PPO]: 1032,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 21356,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 32843
    },
    "Jan 05 2025": {
        [NAMES.TROOPS]: 796490,
        [NAMES.TANKS]: 9686,
        [NAMES.BMP_BTR]: 20119,
        [NAMES.ARTA]: 21603,
        [NAMES.PPO]: 1032,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 21447,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 32940
    },
    "Jan 06 2025": {
        [NAMES.TROOPS]: 798040,
        [NAMES.TANKS]: 9700,
        [NAMES.BMP_BTR]: 20164,
        [NAMES.ARTA]: 21665,
        [NAMES.PPO]: 1034,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 21625,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 33056
    },
    "Jan 07 2025": {
        [NAMES.TROOPS]: 800010,
        [NAMES.TANKS]: 9710,
        [NAMES.BMP_BTR]: 20189,
        [NAMES.ARTA]: 21710,
        [NAMES.PPO]: 1038,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 21708,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 33226
    },
    "Jan 08 2025": {
        [NAMES.TROOPS]: 801670,
        [NAMES.TANKS]: 9714,
        [NAMES.BMP_BTR]: 20205,
        [NAMES.ARTA]: 21729,
        [NAMES.PPO]: 1038,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 21727,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 33307
    },
    "Jan 09 2025": {
        [NAMES.TROOPS]: 803100,
        [NAMES.TANKS]: 9731,
        [NAMES.BMP_BTR]: 20221,
        [NAMES.ARTA]: 21765,
        [NAMES.PPO]: 1038,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 21813,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 33387
    },
    "Jan 10 2025": {
        [NAMES.TROOPS]: 804930,
        [NAMES.TANKS]: 9741,
        [NAMES.BMP_BTR]: 20253,
        [NAMES.ARTA]: 21789,
        [NAMES.PPO]: 1041,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 21884,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 33473
    },
    "Jan 11 2025": {
        [NAMES.TROOPS]: 806500,
        [NAMES.TANKS]: 9751,
        [NAMES.BMP_BTR]: 20271,
        [NAMES.ARTA]: 21817,
        [NAMES.PPO]: 1042,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 21958,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 33534
    },
    "Jan 12 2025": {
        [NAMES.TROOPS]: 808250,
        [NAMES.TANKS]: 9756,
        [NAMES.BMP_BTR]: 20289,
        [NAMES.ARTA]: 21839,
        [NAMES.PPO]: 1042,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 22021,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 33598
    },
    "Jan 13 2025": {
        [NAMES.TROOPS]: 809760,
        [NAMES.TANKS]: 9764,
        [NAMES.BMP_BTR]: 20315,
        [NAMES.ARTA]: 21876,
        [NAMES.PPO]: 1044,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 22204,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 33708
    },
    "Jan 14 2025": {
        [NAMES.TROOPS]: 811090,
        [NAMES.TANKS]: 9767,
        [NAMES.BMP_BTR]: 20326,
        [NAMES.ARTA]: 21923,
        [NAMES.PPO]: 1044,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 22276,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 33791
    },
    "Jan 15 2025": {
        [NAMES.TROOPS]: 812670,
        [NAMES.TANKS]: 9780,
        [NAMES.BMP_BTR]: 20348,
        [NAMES.ARTA]: 21975,
        [NAMES.PPO]: 1046,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 22383,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 33995
    },
    "Jan 16 2025": {
        [NAMES.TROOPS]: 814150,
        [NAMES.TANKS]: 9791,
        [NAMES.BMP_BTR]: 20368,
        [NAMES.ARTA]: 22015,
        [NAMES.PPO]: 1046,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 22503,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 34132
    },
    "Jan 17 2025": {
        [NAMES.TROOPS]: 815820,
        [NAMES.TANKS]: 9803,
        [NAMES.BMP_BTR]: 20381,
        [NAMES.ARTA]: 22019,
        [NAMES.PPO]: 1046,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 22566,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 34256
    },
    "Jan 18 2025": {
        [NAMES.TROOPS]: 817160,
        [NAMES.TANKS]: 9803,
        [NAMES.BMP_BTR]: 20394,
        [NAMES.ARTA]: 22040,
        [NAMES.PPO]: 1046,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 22579,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 34325
    },
    "Jan 19 2025": {
        [NAMES.TROOPS]: 818740,
        [NAMES.TANKS]: 9811,
        [NAMES.BMP_BTR]: 20412,
        [NAMES.ARTA]: 22055,
        [NAMES.PPO]: 1046,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 22615,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 34401
    },
    "Jan 20 2025": {
        [NAMES.TROOPS]: 820430,
        [NAMES.TANKS]: 9821,
        [NAMES.BMP_BTR]: 20454,
        [NAMES.ARTA]: 22074,
        [NAMES.PPO]: 1049,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 22768,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 34488
    },
    "Jan 21 2025": {
        [NAMES.TROOPS]: 822030,
        [NAMES.TANKS]: 9833,
        [NAMES.BMP_BTR]: 20477,
        [NAMES.ARTA]: 22134,
        [NAMES.PPO]: 1050,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 22898,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 34682
    },
    "Jan 22 2025": {
        [NAMES.TROOPS]: 823980,
        [NAMES.TANKS]: 9844,
        [NAMES.BMP_BTR]: 20485,
        [NAMES.ARTA]: 22194,
        [NAMES.PPO]: 1050,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 23039,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 34837
    },
    "Jan 23 2025": {
        [NAMES.TROOPS]: 825320,
        [NAMES.TANKS]: 9850,
        [NAMES.BMP_BTR]: 20497,
        [NAMES.ARTA]: 22256,
        [NAMES.PPO]: 1050,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 23111,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 34905
    },
    "Jan 24 2025": {
        [NAMES.TROOPS]: 826820,
        [NAMES.TANKS]: 9852,
        [NAMES.BMP_BTR]: 20508,
        [NAMES.ARTA]: 22295,
        [NAMES.PPO]: 1050,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 23162,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 34992
    },
    "Jan 25 2025": {
        [NAMES.TROOPS]: 828470,
        [NAMES.TANKS]: 9859,
        [NAMES.BMP_BTR]: 20545,
        [NAMES.ARTA]: 22309,
        [NAMES.PPO]: 1050,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 23213,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 35071
    },
    "Jan 26 2025": {
        [NAMES.TROOPS]: 830190,
        [NAMES.TANKS]: 9868,
        [NAMES.BMP_BTR]: 20549,
        [NAMES.ARTA]: 22323,
        [NAMES.PPO]: 1050,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 23253,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 35124
    },
    "Jan 27 2025": {
        [NAMES.TROOPS]: 831620,
        [NAMES.TANKS]: 9871,
        [NAMES.BMP_BTR]: 20561,
        [NAMES.ARTA]: 22339,
        [NAMES.PPO]: 1050,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 23327,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 35183
    },
    "Jan 28 2025": {
        [NAMES.TROOPS]: 833000,
        [NAMES.TANKS]: 9876,
        [NAMES.BMP_BTR]: 20573,
        [NAMES.ARTA]: 22366,
        [NAMES.PPO]: 1050,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 23399,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 35269
    },
    "Jan 29 2025": {
        [NAMES.TROOPS]: 834670,
        [NAMES.TANKS]: 9886,
        [NAMES.BMP_BTR]: 20597,
        [NAMES.ARTA]: 22395,
        [NAMES.PPO]: 1050,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 23456,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 35366
    },
    "Jan 30 2025": {
        [NAMES.TROOPS]: 835940,
        [NAMES.TANKS]: 9890,
        [NAMES.BMP_BTR]: 20614,
        [NAMES.ARTA]: 22412,
        [NAMES.PPO]: 1050,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 23510,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 35451
    },
    "Jan 31 2025": {
        [NAMES.TROOPS]: 837610,
        [NAMES.TANKS]: 9893,
        [NAMES.BMP_BTR]: 20631,
        [NAMES.ARTA]: 22445,
        [NAMES.PPO]: 1050,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 23573,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 35552
    },
    "Feb 01 2025": {
        [NAMES.TROOPS]: 839040,
        [NAMES.TANKS]: 9902,
        [NAMES.BMP_BTR]: 20653,
        [NAMES.ARTA]: 22493,
        [NAMES.PPO]: 1050,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 23694,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 35629
    },
    "Feb 02 2025": {
        [NAMES.TROOPS]: 840360,
        [NAMES.TANKS]: 9908,
        [NAMES.BMP_BTR]: 20667,
        [NAMES.ARTA]: 22538,
        [NAMES.PPO]: 1050,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 23793,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 35709
    },
    "Feb 03 2025": {
        [NAMES.TROOPS]: 841660,
        [NAMES.TANKS]: 9920,
        [NAMES.BMP_BTR]: 20685,
        [NAMES.ARTA]: 22598,
        [NAMES.PPO]: 1053,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 23911,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 35798
    },
    "Feb 04 2025": {
        [NAMES.TROOPS]: 842930,
        [NAMES.TANKS]: 9938,
        [NAMES.BMP_BTR]: 20709,
        [NAMES.ARTA]: 22655,
        [NAMES.PPO]: 1053,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 24003,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 35921
    },
    "Feb 05 2025": {
        [NAMES.TROOPS]: 844070,
        [NAMES.TANKS]: 9947,
        [NAMES.BMP_BTR]: 20721,
        [NAMES.ARTA]: 22707,
        [NAMES.PPO]: 1053,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 24102,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 36078
    },
    "Feb 06 2025": {
        [NAMES.TROOPS]: 845310,
        [NAMES.TANKS]: 9965,
        [NAMES.BMP_BTR]: 20737,
        [NAMES.ARTA]: 22753,
        [NAMES.PPO]: 1055,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 24185,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 36211
    },
    "Feb 07 2025": {
        [NAMES.TROOPS]: 846650,
        [NAMES.TANKS]: 9975,
        [NAMES.BMP_BTR]: 20755,
        [NAMES.ARTA]: 22785,
        [NAMES.PPO]: 1056,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 24301,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 36307
    },
    "Feb 08 2025": {
        [NAMES.TROOPS]: 847860,
        [NAMES.TANKS]: 9981,
        [NAMES.BMP_BTR]: 20777,
        [NAMES.ARTA]: 22820,
        [NAMES.PPO]: 1056,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 24403,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 36402
    },
    "Feb 09 2025": {
        [NAMES.TROOPS]: 849320,
        [NAMES.TANKS]: 9992,
        [NAMES.BMP_BTR]: 20797,
        [NAMES.ARTA]: 22844,
        [NAMES.PPO]: 1056,
        [NAMES.JETS]: 370,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 24486,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 36529
    },
    "Feb 10 2025": {
        [NAMES.TROOPS]: 850490,
        [NAMES.TANKS]: 10001,
        [NAMES.BMP_BTR]: 20813,
        [NAMES.ARTA]: 22879,
        [NAMES.PPO]: 1059,
        [NAMES.JETS]: 370,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 24623,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 36638
    },
    "Feb 11 2025": {
        [NAMES.TROOPS]: 851880,
        [NAMES.TANKS]: 10014,
        [NAMES.BMP_BTR]: 20844,
        [NAMES.ARTA]: 22923,
        [NAMES.PPO]: 1060,
        [NAMES.JETS]: 370,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 24755,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 36807
    },
    "Feb 12 2025": {
        [NAMES.TROOPS]: 853030,
        [NAMES.TANKS]: 10023,
        [NAMES.BMP_BTR]: 20871,
        [NAMES.ARTA]: 22976,
        [NAMES.PPO]: 1061,
        [NAMES.JETS]: 370,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 24919,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 36928
    },
    "Feb 13 2025": {
        [NAMES.TROOPS]: 854280,
        [NAMES.TANKS]: 10040,
        [NAMES.BMP_BTR]: 20894,
        [NAMES.ARTA]: 23034,
        [NAMES.PPO]: 1063,
        [NAMES.JETS]: 370,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 25072,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 37096
    },
    "Feb 14 2025": {
        [NAMES.TROOPS]: 855480,
        [NAMES.TANKS]: 10057,
        [NAMES.BMP_BTR]: 20910,
        [NAMES.ARTA]: 23115,
        [NAMES.PPO]: 1066,
        [NAMES.JETS]: 370,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 25224,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 37252
    },
    "Feb 15 2025": {
        [NAMES.TROOPS]: 856660,
        [NAMES.TANKS]: 10068,
        [NAMES.BMP_BTR]: 20927,
        [NAMES.ARTA]: 23174,
        [NAMES.PPO]: 1067,
        [NAMES.JETS]: 370,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 25341,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 37379
    },
    "Feb 16 2025": {
        [NAMES.TROOPS]: 858390,
        [NAMES.TANKS]: 10073,
        [NAMES.BMP_BTR]: 21011,
        [NAMES.ARTA]: 23185,
        [NAMES.PPO]: 1067,
        [NAMES.JETS]: 370,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 25377,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 37456
    },
    "Feb 17 2025": {
        [NAMES.TROOPS]: 859920,
        [NAMES.TANKS]: 10089,
        [NAMES.BMP_BTR]: 21063,
        [NAMES.ARTA]: 23222,
        [NAMES.PPO]: 1067,
        [NAMES.JETS]: 370,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 25505,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 37605
    },
    "Feb 18 2025": {
        [NAMES.TROOPS]: 861090,
        [NAMES.TANKS]: 10101,
        [NAMES.BMP_BTR]: 21075,
        [NAMES.ARTA]: 23275,
        [NAMES.PPO]: 1068,
        [NAMES.JETS]: 370,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 25686,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 37679
    },
    "Feb 19 2025": {
        [NAMES.TROOPS]: 862390,
        [NAMES.TANKS]: 10120,
        [NAMES.BMP_BTR]: 21098,
        [NAMES.ARTA]: 23347,
        [NAMES.PPO]: 1072,
        [NAMES.JETS]: 370,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 25861,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 37825
    },
    "Feb 20 2025": {
        [NAMES.TROOPS]: 863580,
        [NAMES.TANKS]: 10134,
        [NAMES.BMP_BTR]: 21112,
        [NAMES.ARTA]: 23410,
        [NAMES.PPO]: 1078,
        [NAMES.JETS]: 370,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 26021,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 37961
    },
    "Feb 21 2025": {
        [NAMES.TROOPS]: 864860,
        [NAMES.TANKS]: 10146,
        [NAMES.BMP_BTR]: 21130,
        [NAMES.ARTA]: 23462,
        [NAMES.PPO]: 1080,
        [NAMES.JETS]: 370,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 26156,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 38104
    },
    "Feb 22 2025": {
        [NAMES.TROOPS]: 866000,
        [NAMES.TANKS]: 10161,
        [NAMES.BMP_BTR]: 21139,
        [NAMES.ARTA]: 23528,
        [NAMES.PPO]: 1080,
        [NAMES.JETS]: 370,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 26311,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 38243
    },
    "Feb 23 2025": {
        [NAMES.TROOPS]: 867180,
        [NAMES.TANKS]: 10168,
        [NAMES.BMP_BTR]: 21151,
        [NAMES.ARTA]: 23582,
        [NAMES.PPO]: 1081,
        [NAMES.JETS]: 370,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 26428,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 38334
    },
    "Feb 24 2025": {
        [NAMES.TROOPS]: 868230,
        [NAMES.TANKS]: 10177,
        [NAMES.BMP_BTR]: 21157,
        [NAMES.ARTA]: 23626,
        [NAMES.PPO]: 1081,
        [NAMES.JETS]: 370,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 26445,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 38444
    },
    "Feb 25 2025": {
        [NAMES.TROOPS]: 869530,
        [NAMES.TANKS]: 10182,
        [NAMES.BMP_BTR]: 21168,
        [NAMES.ARTA]: 23652,
        [NAMES.PPO]: 1083,
        [NAMES.JETS]: 370,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 26767,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 38582
    },
    "Feb 26 2025": {
        [NAMES.TROOPS]: 870700,
        [NAMES.TANKS]: 10201,
        [NAMES.BMP_BTR]: 21183,
        [NAMES.ARTA]: 23686,
        [NAMES.PPO]: 1085,
        [NAMES.JETS]: 370,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 26961,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 38702
    },
    "Feb 27 2025": {
        [NAMES.TROOPS]: 871850,
        [NAMES.TANKS]: 10209,
        [NAMES.BMP_BTR]: 21196,
        [NAMES.ARTA]: 23755,
        [NAMES.PPO]: 1086,
        [NAMES.JETS]: 370,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 27102,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 38842
    },
    "Feb 28 2025": {
        [NAMES.TROOPS]: 874560,
        [NAMES.TANKS]: 10222,
        [NAMES.BMP_BTR]: 21234,
        [NAMES.ARTA]: 23847,
        [NAMES.PPO]: 1087,
        [NAMES.JETS]: 370,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 27274,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 38994
    },
    "Mar 01 2025": {
        [NAMES.TROOPS]: 875610,
        [NAMES.TANKS]: 10233,
        [NAMES.BMP_BTR]: 21249,
        [NAMES.ARTA]: 23908,
        [NAMES.PPO]: 1088,
        [NAMES.JETS]: 370,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 27402,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 39101
    },
    "Mar 02 2025": {
        [NAMES.TROOPS]: 876720,
        [NAMES.TANKS]: 10241,
        [NAMES.BMP_BTR]: 21274,
        [NAMES.ARTA]: 23959,
        [NAMES.PPO]: 1091,
        [NAMES.JETS]: 370,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 27594,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 39218
    },
    "Mar 03 2025": {
        [NAMES.TROOPS]: 878070,
        [NAMES.TANKS]: 10252,
        [NAMES.BMP_BTR]: 21290,
        [NAMES.ARTA]: 24019,
        [NAMES.PPO]: 1093,
        [NAMES.JETS]: 370,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 27655,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 39327
    },
    "Mar 04 2025": {
        [NAMES.TROOPS]: 879410,
        [NAMES.TANKS]: 10255,
        [NAMES.BMP_BTR]: 21298,
        [NAMES.ARTA]: 24064,
        [NAMES.PPO]: 1094,
        [NAMES.JETS]: 370,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 27741,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 39428
    },
    "Mar 05 2025": {
        [NAMES.TROOPS]: 880660,
        [NAMES.TANKS]: 10256,
        [NAMES.BMP_BTR]: 21304,
        [NAMES.ARTA]: 24087,
        [NAMES.PPO]: 1094,
        [NAMES.JETS]: 370,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 27849,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 39512
    },
    "Mar 06 2025": {
        [NAMES.TROOPS]: 881800,
        [NAMES.TANKS]: 10261,
        [NAMES.BMP_BTR]: 21311,
        [NAMES.ARTA]: 24108,
        [NAMES.PPO]: 1096,
        [NAMES.JETS]: 370,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 27929,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 39606
    },
    "Mar 07 2025": {
        [NAMES.TROOPS]: 882950,
        [NAMES.TANKS]: 10264,
        [NAMES.BMP_BTR]: 21334,
        [NAMES.ARTA]: 24124,
        [NAMES.PPO]: 1096,
        [NAMES.JETS]: 370,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 28024,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 39678
    },
    "Mar 08 2025": {
        [NAMES.TROOPS]: 883950,
        [NAMES.TANKS]: 10268,
        [NAMES.BMP_BTR]: 21346,
        [NAMES.ARTA]: 24148,
        [NAMES.PPO]: 1099,
        [NAMES.JETS]: 370,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 28172,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 39791
    },
    "Mar 09 2025": {
        [NAMES.TROOPS]: 885130,
        [NAMES.TANKS]: 10274,
        [NAMES.BMP_BTR]: 21360,
        [NAMES.ARTA]: 24206,
        [NAMES.PPO]: 1101,
        [NAMES.JETS]: 370,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 28362,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 39933
    },
    "Mar 10 2025": {
        [NAMES.TROOPS]: 886320,
        [NAMES.TANKS]: 10292,
        [NAMES.BMP_BTR]: 21400,
        [NAMES.ARTA]: 24271,
        [NAMES.PPO]: 1102,
        [NAMES.JETS]: 370,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 28603,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 40071
    },
    "Mar 11 2025": {
        [NAMES.TROOPS]: 887620,
        [NAMES.TANKS]: 10303,
        [NAMES.BMP_BTR]: 21423,
        [NAMES.ARTA]: 24346,
        [NAMES.PPO]: 1103,
        [NAMES.JETS]: 370,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 28801,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 40205
    },
    "Mar 12 2025": {
        [NAMES.TROOPS]: 889050,
        [NAMES.TANKS]: 10306,
        [NAMES.BMP_BTR]: 21430,
        [NAMES.ARTA]: 24390,
        [NAMES.PPO]: 1103,
        [NAMES.JETS]: 370,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 28912,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 40274
    },
    "Mar 13 2025": {
        [NAMES.TROOPS]: 890250,
        [NAMES.TANKS]: 10307,
        [NAMES.BMP_BTR]: 21432,
        [NAMES.ARTA]: 24409,
        [NAMES.PPO]: 1103,
        [NAMES.JETS]: 370,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 28983,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 40355
    },
    "Mar 14 2025": {
        [NAMES.TROOPS]: 891660,
        [NAMES.TANKS]: 10313,
        [NAMES.BMP_BTR]: 21443,
        [NAMES.ARTA]: 24455,
        [NAMES.PPO]: 1103,
        [NAMES.JETS]: 370,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 29136,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 40469
    },
    "Mar 15 2025": {
        [NAMES.TROOPS]: 892840,
        [NAMES.TANKS]: 10315,
        [NAMES.BMP_BTR]: 21458,
        [NAMES.ARTA]: 24483,
        [NAMES.PPO]: 1103,
        [NAMES.JETS]: 370,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 29233,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 40571
    },
    "Mar 16 2025": {
        [NAMES.TROOPS]: 894240,
        [NAMES.TANKS]: 10325,
        [NAMES.BMP_BTR]: 21478,
        [NAMES.ARTA]: 24561,
        [NAMES.PPO]: 1104,
        [NAMES.JETS]: 370,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 29413,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 40684
    },
    "Mar 17 2025": {
        [NAMES.TROOPS]: 895450,
        [NAMES.TANKS]: 10344,
        [NAMES.BMP_BTR]: 21488,
        [NAMES.ARTA]: 24598,
        [NAMES.PPO]: 1104,
        [NAMES.JETS]: 370,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 29467,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 40785
    },
    "Mar 18 2025": {
        [NAMES.TROOPS]: 897010,
        [NAMES.TANKS]: 10352,
        [NAMES.BMP_BTR]: 21509,
        [NAMES.ARTA]: 24640,
        [NAMES.PPO]: 1107,
        [NAMES.JETS]: 370,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 29616,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 40892
    },
    "Mar 19 2025": {
        [NAMES.TROOPS]: 898070,
        [NAMES.TANKS]: 10364,
        [NAMES.BMP_BTR]: 21523,
        [NAMES.ARTA]: 24704,
        [NAMES.PPO]: 1107,
        [NAMES.JETS]: 370,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 29716,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 41003
    },
    "Mar 20 2025": {
        [NAMES.TROOPS]: 899470,
        [NAMES.TANKS]: 10377,
        [NAMES.BMP_BTR]: 21561,
        [NAMES.ARTA]: 24747,
        [NAMES.PPO]: 1110,
        [NAMES.JETS]: 370,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 29895,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 41094
    },
    "Mar 21 2025": {
        [NAMES.TROOPS]: 900800,
        [NAMES.TANKS]: 10394,
        [NAMES.BMP_BTR]: 21589,
        [NAMES.ARTA]: 24848,
        [NAMES.PPO]: 1111,
        [NAMES.JETS]: 370,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 30080,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 41259
    },
    "Mar 22 2025": {
        [NAMES.TROOPS]: 902010,
        [NAMES.TANKS]: 10403,
        [NAMES.BMP_BTR]: 21609,
        [NAMES.ARTA]: 24944,
        [NAMES.PPO]: 1111,
        [NAMES.JETS]: 370,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 30338,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 41425
    },
    "Mar 23 2025": {
        [NAMES.TROOPS]: 903480,
        [NAMES.TANKS]: 10412,
        [NAMES.BMP_BTR]: 21636,
        [NAMES.ARTA]: 25048,
        [NAMES.PPO]: 1116,
        [NAMES.JETS]: 370,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 30495,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 41610
    },
    "Mar 24 2025": {
        [NAMES.TROOPS]: 904760,
        [NAMES.TANKS]: 10420,
        [NAMES.BMP_BTR]: 21652,
        [NAMES.ARTA]: 25129,
        [NAMES.PPO]: 1117,
        [NAMES.JETS]: 370,
        [NAMES.HELI]: 335,
        [NAMES.UAV]: 30641,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 41726
    },
    "Mar 25 2025": {
        [NAMES.TROOPS]: 905940,
        [NAMES.TANKS]: 10425,
        [NAMES.BMP_BTR]: 21670,
        [NAMES.ARTA]: 25190,
        [NAMES.PPO]: 1117,
        [NAMES.JETS]: 370,
        [NAMES.HELI]: 335,
        [NAMES.UAV]: 30759,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 41824
    },
    "Mar 26 2025": {
        [NAMES.TROOPS]: 907220,
        [NAMES.TANKS]: 10430,
        [NAMES.BMP_BTR]: 21685,
        [NAMES.ARTA]: 25207,
        [NAMES.PPO]: 1117,
        [NAMES.JETS]: 370,
        [NAMES.HELI]: 335,
        [NAMES.UAV]: 30818,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 41901
    },
    "Mar 27 2025": {
        [NAMES.TROOPS]: 908890,
        [NAMES.TANKS]: 10438,
        [NAMES.BMP_BTR]: 21701,
        [NAMES.ARTA]: 25265,
        [NAMES.PPO]: 1118,
        [NAMES.JETS]: 370,
        [NAMES.HELI]: 335,
        [NAMES.UAV]: 30926,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 42070
    },
    "Mar 28 2025": {
        [NAMES.TROOPS]: 910750,
        [NAMES.TANKS]: 10455,
        [NAMES.BMP_BTR]: 21762,
        [NAMES.ARTA]: 25387,
        [NAMES.PPO]: 1119,
        [NAMES.JETS]: 370,
        [NAMES.HELI]: 335,
        [NAMES.UAV]: 31070,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 42280
    },
    "Mar 29 2025": {
        [NAMES.TROOPS]: 912490,
        [NAMES.TANKS]: 10478,
        [NAMES.BMP_BTR]: 21796,
        [NAMES.ARTA]: 25451,
        [NAMES.PPO]: 1122,
        [NAMES.JETS]: 370,
        [NAMES.HELI]: 335,
        [NAMES.UAV]: 31234,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 42398
    },
    "Mar 30 2025": {
        [NAMES.TROOPS]: 914000,
        [NAMES.TANKS]: 10492,
        [NAMES.BMP_BTR]: 21816,
        [NAMES.ARTA]: 25507,
        [NAMES.PPO]: 1122,
        [NAMES.JETS]: 370,
        [NAMES.HELI]: 335,
        [NAMES.UAV]: 31345,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 42528
    }
};
